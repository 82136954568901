import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import moment from "moment";
import reportService from "./reports-service";
import exportLimit from "../../common/export-limit.vue";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      sDate: "",
      eDate: "",
      showStartDate: true,
      showEndDate: true,
      startDate: false,
      endDate: false,
      selectedProject: "",
      projectList: [],
      selectedBusiness: "",
      businessData: [],
      selectedWarehouse: "",
      warehouseData: [],
      selectedOEM: "",
      OEMData: [],
      dateSelected: false,
      runDisabled: true,
      specificDialog: false,
      partClassSearchValid: false,
      showBusiness: false,
      showWarehouse: false,
      showOEM: false,
      repairDispositionData: [],
      excelName: "",
      totalRecords: 0,
      exportDisable: true,
      export50kData: false,
      exportDialog: false,
      showData: false,
      apiObj: {
        pass: 0,
        userid: 0,
        lcsdate: "",
        lcedate: "",
        proj_key: 0,
        bu_key: 0,
        ware_key: 0,
        oem_key: 0,
      },
      headerRepairDisposition: [
        { text: "Project", value: "Project", class: "primary customwhite--text" },
        { text: "Transaction ID", value: "TranId", class: "primary customwhite--text" },
        { text: "Warehouse", value: "Ware", class: "primary customwhite--text" },
        { text: "D1 Order", value: "D1 Order", class: "primary customwhite--text" },
        { text: "RMA", value: "RMA", class: "primary customwhite--text" },
        { text: "Order Type", value: "Order Type", class: "primary customwhite--text" },
        { text: "Base P/N", value: "Base P/N", class: "primary customwhite--text" },
        { text: "Comp P/N", value: "Comp P/N", class: "primary customwhite--text" },
        { text: "Description", value: "Descrip", class: "primary customwhite--text" },
        { text: "Model", value: "Model", class: "primary customwhite--text" },
        { text: "Class", value: "Class", class: "primary customwhite--text" },
        { text: "Size", value: "Size", class: "primary customwhite--text" },
        { text: "Type", value: "Type", class: "primary customwhite--text" },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        { text: "Serial", value: "Serial", class: "primary customwhite--text" },
        { text: "Comp S/N", value: "Comp S/N", class: "primary customwhite--text" },
        { text: "Date Received", value: "Date Rcvd", class: "primary customwhite--text" },
        { text: "Disposition Date", value: "Disposition Date", class: "primary customwhite--text" },
        { text: "Test Path", value: "Test Path", class: "primary customwhite--text" },
        { text: "Final Disposition Location", value: "Final Disposition Location", class: "primary customwhite--text" },
        { text: "Complaint", value: "Complaint", class: "primary customwhite--text" },
        { text: "ECO", value: "ECO", class: "primary customwhite--text" },
        { text: "Observed Damage", value: "Observed Damage", class: "primary customwhite--text" },
        { text: "Sym Description", value: "Sym Descrip", class: "primary customwhite--text" },
        { text: "Parent Failure Description", value: "Parent Failure Description", class: "primary customwhite--text" },
        { text: "Primary Failure Description", value: "Primary Fail Description", class: "primary customwhite--text" },
        { text: "Repair Description", value: "Repair Description", class: "primary customwhite--text" },
        { text: "Flex Field 1", value: "Flex Field 1", class: "primary customwhite--text" },
        { text: "Flex Field 2", value: "Flex Field 2", class: "primary customwhite--text" },
        { text: "Flex Field 3", value: "Flex Field 3", class: "primary customwhite--text" },
      ],
      json_fields: {
        Project: "Project",
        TranID: "TranId",
        Warehouse: "Ware",
        "D1 Order": "D1 Order",
        RMA: "RMA",
        "Order Type": "Order Type",
        "Base P/N": "Base P/N",
        "Comp P/N": "Comp P/N",
        Description: "Descrip",
        Model: "Model",
        Class: "Class",
        Size: "Size",
        Type: "Type",
        BCN: "BCN",
        Serial: "Serial",
        "Comp S/N": "Comp S/N",
        "Date Received": "Date Rcvd",
        "Disposition Rate": "Disposition Date",
        "Test Path": "Test Path",
        "Final Disposition Location": "Final Disposition Location",
        Complaint: "Complaint",
        ECO: "ECO",
        "Observed Damage": "Observed Damage",
        "Sym Description": "Sym Description",
        "Parent Failure Description": "Parent Failure Description",
        "Primary Fail Description": "Primary Fail Description",
        "Repair Description": "Repair Description",
        "Flex Field 1": "Flex Field 1",
        "Flex Field 2": "Flex Field 2",
        "Flex Field 3": "Flex Field 3",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.sDate ? moment(this.sDate).format("MM/DD/YYYY") : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.eDate ? moment(this.eDate).format("MM/DD/YYYY") : moment(new Date()).format("MM/DD/YYYY");
    },
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.showStartDate = false;
      this.showEndDate = false;
      this.$nextTick(() => {
        this.showStartDate = true;
        this.sDate = null;
        this.showEndDate = true;
        this.eDate = null;
      });
      this.selectedProject = "";
      this.projectList = [];
      this.selectedBusiness = "";
      this.businessData = [];
      this.selectedWarehouse = "";
      this.warehouseData = [];
      this.selectedOEM = "";
      this.OEMData = [];
      this.dateSelected = false;
      this.runDisabled = true;
      this.specificDialog = false;
      this.partClassSearchValid = false;
      this.showBusiness = false;
      this.showWarehouse = false;
      this.showOEM = false;
      this.repairDispositionData = [];
      this.excelName = "";
      this.totalRecords = 0;
      this.exportDisable = true;
      this.export50kData = false;
      this.exportDialog = false;
      this.apiObj = {
        pass: 0,
        userid: 0,
        lcsdate: "",
        lcedate: "",
        proj_key: 0,
        bu_key: 0,
        ware_key: 0,
        oem_key: 0,
      };
      this.showData = false;
    },
    // set end Date
    setEndDate() {
      let minEndDate = new Date(this.sDate);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000).toISOString().substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0).toISOString().substr(0, 10);
    },
    //On click continue
    async onContinue() {
      !this.sDate ? (this.sDate = new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10)) : "";
      this.apiObj.pass = 0;
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.userid = parseInt(this.userId);
      let showMessage = false;
      let projData = await reportService.repairDispositionReport("post", this.apiObj, showMessage);
      if (projData.message !== "NA") {
        this.projectList = projData;
        this.dateSelected = true;
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Data Available change the Start Date",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.dateSelected = false;
      }
    },
    //Project Selected
    async onChangeProject() {
      this.apiObj.pass = 1;
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.userid = parseInt(this.userId);
      this.apiObj.proj_key = parseInt(this.selectedProject);
      let showMessage = false;
      let busData = await reportService.repairDispositionReport("post", this.apiObj, showMessage);
      if (busData.message !== "NA") {
        this.businessData = busData;
        this.selectedBusiness = "";
        this.selectedOEM = "";
        this.selectedWarehouse = "";
        this.runDisabled = true;
        this.showBusiness = true;
        this.showWarehouse = false;
        this.showOEM = false;
        this.repairDispositionData = [];
        this.totalRecords = 0;
        this.exportDisable = true;
        this.showData = false;
      }
    },
    //Business selected
    async businessSelected() {
      this.apiObj.pass = 2;
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.userid = parseInt(this.userId);
      this.apiObj.proj_key = parseInt(this.selectedProject);
      this.apiObj.bu_key = parseInt(this.selectedBusiness);
      let showMessage = false;
      let otherData = await reportService.repairDispositionReport("post", this.apiObj, showMessage);
      if (otherData.message !== "NA") {
        this.warehouseData = otherData;
        this.OEMData = otherData;
        this.selectedWarehouse = "";
        this.selectedOEM = "";
        this.runDisabled = true;
        this.showWarehouse = false;
        this.showOEM = true;
        this.repairDispositionData = [];
        this.totalRecords = 0;
        this.exportDisable = true;
        this.showData = false;
      }
    },
    //OEM selected
    OEMSelected() {
      this.selectedWarehouse = "";
      this.showWarehouse = true;
      this.runDisabled = true;
      this.repairDispositionData = [];
      this.totalRecords = 0;
      this.exportDisable = true;
      this.showData = false;
    },
    //Warehouse Selected
    warehouseSelected() {
      this.runDisabled = false;
      this.repairDispositionData = [];
      this.totalRecords = 0;
      this.exportDisable = true;
      this.showData = false;
    },
    //Run Report
    async runReport() {
      this.apiObj.pass = 3;
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.userid = parseInt(this.userId);
      this.apiObj.proj_key = parseInt(this.selectedProject);
      this.apiObj.bu_key = parseInt(this.selectedBusiness);
      this.apiObj.ware_key = parseInt(this.selectedWarehouse);
      this.apiObj.oem_key = parseInt(this.selectedOEM);
      let showMessage = false;
      let reportData = await reportService.repairDispositionReport("post", this.apiObj, showMessage);
      if (reportData.message !== "NA") {
        if (reportData.length > 0) {
          const newArr = reportData.map((obj) => {
            return {
              ...obj,
              "Date Rcvd": Utility.convertESTToLocal(obj["Date Rcvd"]),
              "Disposition Date": Utility.convertESTToLocal(obj["Disposition Date"]),
            };
          });
          this.repairDispositionData = newArr;
          this.totalRecords = reportData.length;
          this.exportDisable = false;
          this.showData = true;
        } else {
          this.repairDispositionData = [];
          this.totalRecords = 0;
          this.exportDisable = true;
          this.showData = false;
          let Alert = {
            type: "error",
            isShow: true,
            message: "No records based on the selected criteria",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else {
        this.repairDispositionData = [];
        this.totalRecords = 0;
        this.exportDisable = true;
        this.showData = false;
        let Alert = {
          type: "error",
          isShow: true,
          message: "No records based on the selected criteria",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Export Data using component
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    //To export the Excel file
    async exportToExcel() {
      if (this.totalRecords <= 15000) {
        this.startDownload();
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Repair Disposition Report");
        const borderStyle = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };
        const colors = {
          grey: { type: "pattern", pattern: "solid", fgColor: { argb: "D3D3D3" } },
        };
        //Add Header Rows
        worksheet.addRow([
          "Project",
          "TranID",
          "Warehouse",
          "D1 Order",
          "RMA",
          "Order Type",
          "Base P/N",
          "Comp P/N",
          "Description",
          "Model",
          "Class",
          "Size",
          "Type",
          "BCN",
          "Serial",
          "Comp S/N",
          "Date Received",
          "Disposition Rate",
          "Test Path",
          "Final Disposition Location",
          "Complaint",
          "ECO",
          "Observed Damage",
          "Sym Description",
          "Parent Failure Description",
          "Primary Fail Description",
          "Repair Description",
          "Flex Field 1",
          "Flex Field 2",
          "Flex Field 3",
        ]);
        // Style configuration
        const headerStyle = {
          font: { bold: true },
          alignment: { horizontal: "center", vertical: "middle" },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
          fill: { type: "pattern", pattern: "solid", fgColor: { argb: "D3D3D3" } },
        };
        // Apply styles to the header rows
        worksheet.getRow(1).eachCell((cell) => {
          cell.style = { ...headerStyle, fill: colors.grey };
          cell.border = borderStyle;
        });
        this.apiObj.pass = 3;
        this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
        this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
        this.apiObj.userid = parseInt(this.userId);
        this.apiObj.proj_key = parseInt(this.selectedProject);
        this.apiObj.bu_key = parseInt(this.selectedBusiness);
        this.apiObj.ware_key = parseInt(this.selectedWarehouse);
        this.apiObj.oem_key = parseInt(this.selectedOEM);
        const response = await this.axios.post("/rt/repair_disposition_report", this.apiObj);
        let responseData = JSON.parse(response.data.body.message);
        const newArr = responseData.map((obj) => {
          return {
            ...obj,
            "Date Rcvd": Utility.convertESTToLocal(obj["Date Rcvd"]),
            "Disposition Date": Utility.convertESTToLocal(obj["Disposition Date"]),
          };
        });
        this.export50kData = false;
        //Add your data rows
        newArr.forEach((item) => {
          const row = worksheet.addRow([
            item.Project,
            item.TranID,
            item.Ware,
            item["D1 Order"],
            item.RMA,
            item["Order Type"],
            item["Base P/N"],
            item["Comp P/N"],
            item.Descrip,
            item.Model,
            item.Class,
            item.Size,
            item.Type,
            item.BCN,
            item.Serial,
            item["Comp S/N"],
            item["Date Rcvd"],
            item["Disposition Date"],
            item["Test Path"],
            item["Final Disposition Location"],
            item.Complaint,
            item.ECO,
            item["Observed Damage"],
            item["Sym Description"],
            item["Expected Serial_No"],
            item["Parent Failure Description"],
            item["Primary Fail Description"],
            item["Repair Description"],
            item["Flex Field 1"],
            item["Flex Field 2"],
            item["Flex Field 3"],
          ]);
          row.eachCell({ includeEmpty: true }, (cell) => {
            //Apply borders
            cell.border = borderStyle;
          });
        });
        //Download the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), Utility.excelFileName("Repair_Disposition_Report"));
        this.finishDownload();
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //End the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
